@import url("https://fonts.googleapis.com/css2?family=Alata&family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

.bg-orange {
	background-color: #ff6e4a;
}
.border-orange {
	border: 1px solid #ff6e4a;
}
.border-l-orange {
	border-left: 1px solid #ff6e4a;
}
.border-b-2 {
	border-bottom: 2px solid #ff6e4a;
}
.text-orange {
	color: #ff6e4a;
}

.orange {
	color: #ff6e4a;
}
.sponsor {
	/* filter: blur(15px); */
	pointer-events: none;
}
/* --------------------------navbar ---------------------------- */

.nav-holder {
	position: sticky;
}
.navbar {
	background-color: #000;
	width: 100%;
	padding: 20px 20px;
}
.navbar > img {
	width: 120px;
}

.navlink {
	color: #fff;
	font-weight: 400;
	transition: all 0.4s ease-in-out;
}
.navlink:hover {
	color: #ff6e4a;
	margin-bottom: 5px;
}
.img-logo {
	cursor: pointer;
}
.nav-btn {
	background-color: #ff6e4a;
	color: #fff;
	border-radius: 5px;
	padding: 12px 70px;
	font-size: 16px;
	font-weight: 500;
}
/* For screens up to 1440px */
@media screen and (max-width: 1440px) {
	.nav-holder {
		position: sticky;
	}
	.navbar {
		background-color: #000;
		width: 100%;
		padding: 20px 20px;
		/* justify-content: space-between;
	z-index: 1000; */
	}
	.line-holder {
		display: flex;
		flex-direction: column;
		align-items: end;
	}
	.lines {
		width: 300px;
	}
}
@media screen and (max-width: 1200px) {
	.nav-holder {
		position: sticky;
	}
	.navbar {
		background-color: #000;
		width: 100%;
		padding: 10px 20px;
		/* justify-content: space-between;
	z-index: 1000; */
	}
	.line-holder {
		display: flex;
		flex-direction: column;
		align-items: end;
	}
	.lines {
		width: 300px;
	}
}
@media screen and (max-width: 1024px) {
	.nav-holder {
		position: sticky;
	}
	.navbar {
		background-color: #000;
		width: 100%;
		padding: 10px 20px;
		/* justify-content: space-between;
	z-index: 1000; */
	}
	.line-holder {
		display: flex;
		flex-direction: column;
		align-items: end;
	}
	.lines {
		width: 250px;
	}
}
@media screen and (max-width: 996px) {
	.nav-holder {
		position: sticky;
	}
	.navbar {
		background-color: #000;
		width: 100%;
		padding: 10px 20px;
		/* justify-content: space-between;
	z-index: 1000; */
	}
}
@media screen and (max-width: 767px) {
	.line-holder {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.lines {
		width: 250px;
	}
}
@media screen and (max-width: 575px) {
	.nav-holder {
		position: sticky;
	}
	.navbar {
		background-color: #000;
		width: 100%;
		padding: 10px 0;
	}
}
@media screen and (max-width: 480px) {
	.nav-holder {
		padding: 0px 20px;
	}
	.navbar {
		background-color: #000;
		width: 100%;
		padding: 10px 10px;
	}
	.small-nav {
		z-index: 500 !important;
	}
}
@media screen and (max-width: 360px) {
	.nav-holder {
		padding: 0px 15px;
	}
	.navbar {
		background-color: #000;
		width: 100%;
		padding: 10px 10px;
	}
}

/* ------------------------banner ------------------------ */

.banner-container {
	position: relative;
	padding-left: 80px;
	width: 100%;
	overflow: hidden;
	height: 800px;
	/* flex-shrink: 0; */
	background: url(../assets/Subtract.svg);
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
}

.banner-text {
	width: 70%;
	animation: slideFromLeft 1s ease-out;
}
.banner-text h1 {
	font-size: 55px;
}

.banner-text p {
	width: 100%;
	font-size: 14px;
	line-height: 28px;
	color: #fff;
}
.banner-buttons {
	width: 100%;
	display: flex;
	align-items: center;
	gap: 20px;
	justify-content: space-between;
}
.cursor {
	position: absolute;
	display: flex;
	gap: 5px;
	align-items: center;
	left: 30%;
	display: inline-flex;
	align-items: center;
	gap: 8px;
	cursor: pointer;
	user-select: none;
}
@keyframes clickAnimation {
	0%,
	50% {
		transform: scale(0.95);
		transition: all 0.2s ease-in-out;
	}
	100% {
		transform: scale(1);
		transform: translateY(-50%);
	}
}

.animate-click {
	animation: clickAnimation 2s ease-in-out infinite; /* Adjust duration and timing as needed */
}

.partnership {
	background: #fff;
	color: #000;
	font-size: 18px;
	bottom: 0%;
	right: 0;
	display: flex;
	align-items: center;
	min-width: 100px;
	max-width: 100%;
	padding: 30px;
	border-radius: 12px 0 0 12px;
	justify-content: space-between;
}
.partnership p {
	width: 300px;
	font-weight: 500;
}
.partnership p span {
	color: #1d6e01;
	font-weight: 700;
}

.animation-container {
	overflow-x: hidden;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 1);
	pointer-events: none;
	margin-left: 100px;
	margin-top: -50px;
	width: 982.598px;
	/* height: 864.914px; */
	transform: rotate(20.81deg);
	flex-shrink: 0;
	border-radius: 30px;
}
.animation-1 {
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: absolute;
	animation: slideFromRight 1s ease-out;
	margin-top: -50px;
}

@keyframes slideFromLeft {
	from {
		transform: translateX(-100%);
	}
	to {
		transform: translateX(0);
	}
}

@keyframes slideFromRight {
	from {
		transform: translateX(100%);
	}
	to {
		transform: translateX(0);
	}
}

/* For screens up to 1440px */
@media screen and (max-width: 1440px) {
	.banner-container {
		padding: 0 5%;
		height: 700px;
	}

	.animation-container {
		margin-left: 5%;
	}
	.banner-text {
		margin-top: 100px;
		z-index: 20;
		width: 100%;
	}
	.banner-buttons {
		display: flex;
		align-items: center;
	}
}

/* For screens up to 1200px */
@media screen and (max-width: 1200px) {
	.banner-container {
		height: 800px;
		padding: 0 7%;
	}
	.animation-container {
		margin-left: 7%;
	}
	.banner-text {
		margin-top: 140px;
		z-index: 20;
		width: 100%;
	}
	.banner-text h1 {
		font-size: 50px;
	}
	.banner-buttons {
		display: flex;
		align-items: center;
	}
}

/* For screens up to 1024px */
@media screen and (max-width: 1024px) {
	.banner-text {
		z-index: 20;
		margin-top: 80px;
		width: 70%;
	}
	.animation-container {
		display: none;
	}
	.banner-container {
		width: 100%;
		height: 700px;
	}
}

/* For screens up to 996px */
@media screen and (max-width: 996px) {
	.banner-container {
		height: 700px;
		z-index: 0 !important;
	}
	.banner-text {
		z-index: 20;
	}
	.animation-container {
		z-index: 0 !important;
		margin-left: 0;
		margin-top: 0;
	}
}

/* For screens up to 768px */
@media screen and (max-width: 768px) {
	.banner-container {
		padding: 0 9%;
		height: 800px;
	}
	.banner-text {
		z-index: 20;
		width: 90%;
	}
	.animation-container {
		transform: rotate(0);
	}
	.banner-text {
		align-items: center;
	}
	.banner-buttons {
		flex-direction: column;
	}
	.animation-1 {
		position: relative;
	}
}

/* For screens up to 575px */
@media screen and (max-width: 575px) {
	.banner-text {
		width: 90%;
		gap: 10px;
	}
}

/* For screens up to 480px */
@media screen and (max-width: 480px) {
	.banner-container {
		height: 800px;
		padding-left: 3%;
		overflow-x: hidden;
	}

	.banner-text h1 {
		font-size: 42px;
		line-height: 40px;
	}
	.partnership {
		background: #fff;
		color: #000;
		font-size: 16px;
		bottom: 0%;
		right: 0;
		display: flex;
		align-items: center;
		min-width: 200px;
		max-width: 100%;
		padding: 20px;
		justify-content: space-between;
	}
	.partnership p {
		width: 400px;
		font-weight: 500;
	}
	.partnership p span {
		color: #1d6e01;
		font-weight: 700;
	}
	#section-heading {
		font-size: 40px;
	}
}

/* For screens up to 360px */
@media screen and (max-width: 360px) {
	.banner-container {
		height: 700px !important;
	}
	.banner-text {
		width: 90%;
	}
	.banner-text h1 {
		font-size: 38px;
	}
	.banner-text p {
		font-size: 12px;
		line-height: 24px;
	}
}

/* -----------------highlights------------------- */

#highlight {
	background: transparent;
}
/* .highlight-container {
	display: flex;
	gap: 30px;
} */
.highlight-box {
	transform: scale(0.95);
	color: #fff;
	border-radius: 12.054px;
	border: 0.93px solid #f0ca6b;
	background: rgba(255, 255, 255, 0.2);
	box-shadow: 7.188503265380859px 7.188503265380859px 9.884191513061523px 0px
			rgba(255, 255, 255, 0.25) inset,
		-7.188503265380859px -7.188503265380859px 9.884191513061523px 0px
			rgba(255, 255, 255, 0.25) inset;
	backdrop-filter: blur(1.7971258163452148px);
	transition: all 0.4s ease-in-out;
}
.highlight-box:hover {
	transform: scale(1.06);
}

/* For screens up to 1440px */
@media screen and (max-width: 1440px) {
	#highlight {
		margin-top: 100px;
	}
	.highlight-container {
		padding: 30px 0%;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 30px;
		width: 100%;
	}
	.highlight-box {
		transform: scale(1.1);
		padding: 0 10px;
		width: 100%;
		height: auto !important;
	}
}
@media screen and (max-width: 1200px) {
	#highlight {
		margin-top: 100px;
	}
	.highlight-container {
		padding: 30px 0;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;
		width: 100%;
	}
	.highlight-box {
		transform: scale(1);
		padding: 0 10px;
		width: 100%;
		height: auto !important;
	}
}
@media screen and (max-width: 1024px) {
	.highlight-container {
		display: flex;
		width: 70%;
		/* flex-direction: column; */
		align-items: center;
		justify-content: center;
		margin: auto;
		gap: 1px;
	}
	.highlight-box {
		padding: 0 10px;
		transform: scale(1);
	}
	.highlight-box h1 {
		font-size: 25px;
	}
}
@media screen and (max-width: 996px) {
	.highlight-container {
		padding: 0 5%;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 30px;
	}
}
@media screen and (max-width: 768px) {
	.highlight-container {
		margin-top: 50px;
		padding: 0 5%;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;
	}
	.highlight-box {
		padding: 0;
	}
}
@media screen and (max-width: 575px) {
	.highlight-container {
		margin-top: 50px;
		padding: 0 2%;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;
	}
	.highlight-box {
		transform: scale(1.04);
		padding: 0 20px;
	}
	#highlight {
		margin-top: 40px;
	}
	.lines {
		width: 60%;
	}
}
@media screen and (max-width: 480px) {
	#highlight {
		margin-top: 100px !important;
	}
	.highlight-container {
		width: 100%;
		padding: 0 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;
	}
	.highlight-box {
		width: 100% !important;
		padding: 0 40px !important;
	}
	#highlight {
		margin-top: 40px;
	}
	.lines {
		width: 50% !important;
	}
}
@media screen and (max-width: 360px) {
	.lines {
		width: 60%;
	}
	.highlight-container {
		width: 100%;
		padding: 0 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 30px;
	}
}

/* ----------------------------achievements----------------------------- */
#achievements {
	display: flex;
	flex-direction: column;
}
.achievements-heading {
	padding: 0 80px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: flex-start;
}

/* -------------------event-schedule---------------------- */
#event-schedule {
	color: #fff;
	padding: 80px 0;
	font-family: "Plus Jakarta Sans", sans-serif;
}
.event-container {
	width: 80%;
	display: flex;
	margin: 80px auto;
}

@media screen and (max-width: 996px) {
	.achievements-heading {
		padding: 0 3%;
		align-items: center;
	}

	#event-schedule {
		padding: 40px 0;
	}

	.event-container {
		width: 90%;
		flex-direction: column;
		margin: 40px auto;
	}

	.event-list {
		border-left: 1px solid #ff6e4a;
	}
}
@media screen and (max-width: 768px) {
	.achievements-heading {
		padding: 0 3%;
		align-items: center;
	}
	#event-schedule {
		margin-top: 60px;
	}
	#event-schedule {
		padding: 40px 0;
	}
	#schedule {
		display: flex;
		flex-direction: column;
		align-items: center !important;
		text-align: center;
	}
	#schedule > div {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}
	#schedule-split {
		width: 100%;
		display: flex;
		justify-content: center;
	}
	#schedule-split h1 {
		font-size: 25px;
	}
	#date {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;
	}
	.lines {
		width: 100%;
	}

	.event-container {
		width: 90%;
		flex-direction: column;
		margin: 40px auto;
	}

	.event-list {
		border-left: 1px solid #ff6e4a;
	}
}

/* For screens up to 575px */
@media screen and (max-width: 575px) {
	.event-container {
		margin: 20px auto;
	}
	#event-schedule {
		margin-top: 40px;
	}
}
@media screen and (max-width: 480px) {
	.event-container {
		margin: 20px auto;
	}
	#event-schedule {
		margin-top: 40px;
	}
	#schedule {
		display: flex;
		flex-direction: column;
		align-items: center !important;
		text-align: center;
	}
	#schedule > div {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}
	#schedule-split {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	#schedule-split h1 {
		font-size: 18px;
	}
	#date {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;
	}
	.lines {
		width: 100%;
	}
}
@media screen and (max-width: 360px) {
	.event-container {
		margin: 20px auto;
	}
	#schedule {
		display: flex;
		flex-direction: column;
		align-items: center !important;
		text-align: center;
	}
	#schedule > div {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}
	#schedule-split {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	#schedule-split h1 {
		font-size: 16px;
	}
	#schedule-split p {
		font-size: 14px;
	}
	#date {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;
	}
	.lines {
		width: 100%;
	}
}

/* --------------------sponsors-------------------------- */

.sponsors {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 20px;
}
/* For screens up to 768px */
@media screen and (max-width: 768px) {
	.sponsors {
		align-items: center;
	}
}

/* -----------------------photos---------------------------- */

@media screen and (max-width: 768px) {
	#photos {
		margin-top: 40px;
	}
}

/* --------------------footer-------------------------- */

#footer {
	color: #fff;
	background: #000;
	/* padding-left: 80px; */
	padding-right: 80px;
	padding-top: 60px;
	padding-bottom: 40px;
	display: grid;
	gap: 40px;
}
/* For screens up to 768px */
@media screen and (max-width: 768px) {
	#footer {
		padding: 40px 3%;
		grid-template-columns: 1fr; /* Make it a single column */
		gap: 20px;
	}
}

/* For screens up to 480px */
@media screen and (max-width: 480px) {
	#footer {
		padding: 40px 6%;
	}
}
@media screen and (max-width: 360px) {
	#footer {
		padding: 40px 5%;
	}
}

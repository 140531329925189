@import url("https://fonts.googleapis.com/css2?family=Alata&family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.main {
	padding: 100px 0;
	position: relative;
	background-color: #fff6f3;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 50px;
	justify-content: center;
	align-items: center;
	height: fit-content;
}
.required {
	color: #ff6f43;
}
.main img {
	width: 50px;
	position: absolute;
}
form {
	margin: auto;
	width: 60%;
	display: flex;
	flex-direction: column;
	gap: 20px;
}
.formtitle {
	width: fit-content;
	text-align: center;
	display: flex;
	flex-direction: column;
	gap: 5px;
}
.formtitle h1 {
	color: #ff6f43;
	font-size: 45px;
	letter-spacing: -1.5px;
}
.formtitle small {
	font-size: 14px;
}
.formtitle p {
	font-weight: 500;
	font-size: 20px;
	margin-top: 30px;
}

.smallinputholder {
	display: flex;
	gap: 20px;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}

.inputholder {
	display: flex;
	flex-direction: column;
	gap: 5px;
	width: 100%;
}
.inputholder input {
	/* border: .1px solid #8D9396; */
	outline: none;
	padding: 10px;
	height: 50px;
	border-radius: 6px;
	font-size: 14px;
	background-color: #2c2223;
	color: white;
}
.inputholder label {
	font-size: 16px;
	font-weight: 500;
	color: #007bb8;
}
.inputholder input::placeholder {
	color: #757575;
	font-size: 14px;
}

.nontext {
	background-color: #f1f1f1;
}

.inputholder select {
	font-size: 14px;
	color: #fff;
	border: none;
	outline: none;
	padding: 10px;
	height: 50px;
	border-radius: 6px;
	width: 100%;
	background: #2c2223;
}
.inputholder option {
	color: #fff;
}
.inputholder option::placeholder {
	color: #757575;
}

.inputholder textarea {
	/* border: 1px solid #8D9396; */
	outline: none;
	padding: 10px;
	height: 120px;
	border-radius: 6px;
	font-size: 14px;
	background-color: #2c2223;
	color: #fff;
}
.inputholder textarea::placeholder {
	color: #757575;
	font-size: 14px;
}
.labelholder {
	display: flex;
	flex-direction: column;
	gap: 5px;
}
.labelholder p {
	color: #007bb8;
	font-weight: 500;
	font-size: 16px;
}
.labelholder > label {
	background: #2c2223;
	padding: 10px;
	display: flex;
	gap: 20px;
	align-items: center;
	height: 50px;
	color: #fff;
	font-size: 14px;
	transition: all 0.2s ease;
	cursor: pointer;
	border-radius: 10px;
}
.labelholder label:hover {
	border: 1.5px solid #ff6f43;
	font-size: 15px;
}

.labelholder input[type="radio"] {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	width: 18px;
	height: 18px;
	border: 1px solid #ffe3db;
	border-radius: 50%;
	outline: none;
	cursor: pointer;
	margin-left: 8px;
	padding: auto;
}

.labelholder .radioLabel {
	display: inline-flex;
	align-items: center;
	padding: 8px;
	border: transparent;
	border-radius: 8px;
	cursor: pointer;
}

.labelholder input[type="radio"]:checked {
	background-color: #2c2223;
	border: 4px solid #ff6f43;
}

.labelholder .radioLabel span {
	margin-left: 4px;
}

.formbutton {
	background: #ff6f43;
	color: #fff;
	padding: 15px 25px;
	border: none;
	outline: none;
	border-radius: 10px;
	font-size: 16px;
	font-weight: 700;
}

/* Larger Laptops and Desktops (1025px and above) */
@media screen and (min-width: 1025px) {
	.main {
		gap: 50px;
	}

	form {
		width: 60%;
	}

	.formtitle h1 {
		font-size: 45px;
	}
}

/* Tablets and Small Laptops (769px - 1024px) */
@media screen and (min-width: 769px) and (max-width: 1024px) {
	.main {
		gap: 40px;
	}

	form {
		width: 70% !important;
	}

	.formtitle h1 {
		font-size: 35px;
	}
}

/* Mobile Devices (up to 768px) */
@media screen and (max-width: 768px) {
	.main {
		padding: 50px 0;
		gap: 30px;
	}

	form {
		width: 80% !important;
	}

	.formtitle h1 {
		font-size: 30px;
	}
	.smallinputholder {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		width: 100%;
		justify-content: space-between;
		align-items: center;
	}

	.inputholder input,
	.inputholder select {
		font-size: 14px;
		height: 40px;
	}
	.inputholder textarea {
		font-size: 14px;
		height: 80px;
	}

	.labelholder label {
		height: 40px;
		font-size: 14px;
	}
	.labelholder label:hover {
		border: 3px solid #ffe3db;
		font-size: 14px;
	}
}

/* Mobile Devices (up to 480px) */
@media screen and (max-width: 480px) {
	.main {
		padding: 20px 10px;
		gap: 15px;
		width: 100%;
	}

	form {
		width: 90% !important;
	}

	.formtitle h1 {
		font-size: 22px;
	}
	.smallinputholder {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		width: 100%;
		justify-content: space-between;
		align-items: center;
	}
	.inputholder input,
	.inputholder select {
		font-size: 14px;
		height: 40px;
	}
	.inputholder textarea {
		font-size: 14px;
		height: 80px;
	}
	.inputholder label {
		font-size: 12px;
		font-weight: 500;
		color: #007bb8;
	}

	.labelholder label {
		height: 40px;
		font-size: 14px;
	}
	.labelholder label:hover {
		border: 3px solid #ffe3db;
		font-size: 14px;
	}

	.formbutton {
		font-size: 13px;
		padding: 8px 18px;
	}
	.labelholder p {
		color: #007bb8;
		font-weight: 500;
		font-size: 12px;
	}
	.labelholder > label {
		background: #2c2223;
		padding: 10px;
		display: flex;
		gap: 20px;
		align-items: center;
		height: 50px;
		color: #fff;
		font-size: 12px;
		transition: all 0.2s ease;
		cursor: pointer;
		border-radius: 10px;
	}
	.labelholder label:hover {
		border: 0.8px solid #ff6f43;
		font-size: 12px;
	}
}

/* Mobile Devices (up to 360px) */
@media screen and (max-width: 360px) {
	.main {
		padding: 15px 5px;
		gap: 10px;
	}

	form {
		width: 90% !important;
	}

	.formtitle h1 {
		font-size: 20px;
	}
	.smallinputholder {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		width: 100%;
		justify-content: space-between;
		align-items: center;
	}

	.inputholder input,
	.inputholder select {
		font-size: 12px;
		height: 40px;
	}
	.inputholder label {
		font-size: 12px;
		font-weight: 500;
		color: #007bb8;
	}
	.inputholder textarea {
		font-size: 14px;
		height: 80px;
	}

	.labelholder label {
		height: 40px;
		font-size: 12px;
	}
	.labelholder label:hover {
		border: 3px solid #ffe3db;
		font-size: 14px;
	}

	.formbutton {
		font-size: 12px;
		padding: 8px 15px;
	}
	.labelholder p {
		color: #007bb8;
		font-weight: 500;
		font-size: 12px;
	}
	.labelholder > label {
		background: #2c2223;
		padding: 10px;
		display: flex;
		gap: 20px;
		align-items: center;
		height: 50px;
		color: #fff;
		font-size: 12px;
		transition: all 0.2s ease;
		cursor: pointer;
		border-radius: 10px;
	}
	.labelholder label:hover {
		border: 0.8px solid #ff6f43;
		font-size: 12px;
	}
}

/* Additional Styles for All Screen Sizes */

/* You can add any other responsive styles here */

.formbutton {
    width: 100%; /* Remove fixed width */
    background: #FF6F43;
    color: #FFF;
    padding: 12px 20px; /* Adjust padding for better scaling */
    border: none;
    outline: none;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    transition: all .4s ease;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.formbutton:hover{
    color: #FF6F43;
    background: transparent;
    border: 1px solid #FF6F43;
}




@media screen and (max-width: 360px){

    .formbutton {
        width: 100%; /* Remove fixed width */
        background: #FF6F43;
        color: #FFF;
        padding: 6px 10px; /* Adjust padding for better scaling */
        border-radius: 5px;
        font-size: 12px;
        transition: all .4s ease;
    }
    .formbutton:hover{
        color: #FF6F43;
        background: transparent;
        border: 1px solid #FF6F43;
    }

}
@import url("https://fonts.googleapis.com/css2?family=Alata&family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Plus Jakarta Sans", sans-serif;
}

.test-container {
	width: 100% !important;
	margin: 10px auto;
}

.carousel {
	width: 100%;
	height: fit-content;
	position: relative;
	overflow: hidden;
}

.slider {
	height: 600px;
	display: flex;
	width: 500%;
	transition: transform 0.4s ease-in-out;
}

.slider section {
	flex-basis: 100%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	background: linear-gradient(
		to right,
		#ffffff 0%,
		#ffffff 40%,
		#246bce 40%,
		#246bce 100%
	);
}

.test-1 {
	pointer-events: none;
	width: 300px;
}

.quote {
	width: 100%;
	max-width: 600px;
	color: #fff;
	display: flex;
	flex-direction: column;
	gap: 20px;
	position: relative;
}

.quote h3 {
	text-transform: uppercase;
	font-size: 22px;
	font-weight: 700;
}

.quote p {
	line-height: 30px;
	font-size: 18px;
	font-weight: 500;
}

.quote small {
	font-size: 14px;
	font-weight: 500;
}

.quote small span {
	font-style: italic;
	font-size: 14px;
	font-weight: 400;
}

.q1 {
	width: 80px;
}

.q2 {
	pointer-events: none;
	width: 80px;
	display: flex;
	align-self: flex-end;
	margin-top: -20px;
}

.heading {
	pointer-events: none;
	text-align: center;
	margin: 30px 0;
	display: flex;
	flex-direction: column;
	gap: 15px;
}
.heading h1 {
	color: #fff;
	font-size: 40px;
}
.heading p {
	color: #fff;
	font-weight: 300;
	font-size: 24px;
}

.control .arrow {
	position: absolute;
	cursor: pointer;
	top: 55%;
}

.arrow.left {
	left: 50px;
}

.arrow.right {
	right: 50px;
}

.indicators {
	display: flex;
	justify-content: center;
	margin: 30px 0;
	z-index: 2000;
}

.indicator-dot {
	width: 70px;
	height: 5px;
	border-radius: 5px;
	background-color: #fff;
	margin: 10px 2px;
	cursor: pointer;
}

.indicator-dot.active {
	background-color: #ff6e4a;
}

/* Responsive Styles */

@media screen and (max-width: 1200px) {
	.quote {
		max-width: 500px;
	}
}

@media screen and (max-width: 1024px) {
	.quote {
		max-width: 500px;
	}
	.arrow.left {
		left: 20px;
	}

	.arrow.right {
		right: 20px;
	}
	.arrow {
		width: 50px;
		top: 60% !important;
	}
	.quote-mark {
		width: 50px;
	}
}

@media screen and (max-width: 992px) {
	.quote {
		max-width: 400px;
	}
	.test-1 {
		width: 200px;
		margin-top: 40px;
	}
	slider {
		height: fit-content;
	}
	.slider section {
		flex-basis: 100%;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		background: linear-gradient(
			to right,
			#ffffff 0%,
			#ffffff 40%,
			#246bce 40%,
			#246bce 100%
		);
	}
	.quote {
		margin-bottom: 30px;
	}
	.quote img {
		width: 40px;
	}
	.quote h3 {
		text-transform: uppercase;
		font-size: 20px;
		font-weight: 700;
	}

	.quote p {
		line-height: 30px;
		font-size: 14px;
		font-weight: 500;
	}

	.quote small {
		font-size: 14px;
		font-weight: 500;
	}

	.quote small span {
		font-style: italic;
		font-size: 14px;
		font-weight: 400;
	}
	.control .arrow {
		width: 50px;
		bottom: 10px; /* Adjust the distance from the bottom as needed */
	}
}

@media screen and (max-width: 768px) {
	.quote {
		max-width: 400px;
	}
	.test-1 {
		width: 300px;
		margin-top: 20px;
	}
	.quote {
		margin-bottom: 40px;
	}
	.quote img {
		width: 30px;
	}
	.quote h3 {
		text-transform: uppercase;
		font-size: 25px;
		font-weight: 700;
	}

	.quote p {
		line-height: 30px;
		font-size: 18px;
		font-weight: 500;
	}

	.quote small {
		font-size: 14px;
		font-weight: 500;
	}

	.quote small span {
		font-style: italic;
		font-size: 14px;
		font-weight: 400;
	}
	.slider section {
		flex-basis: 100%;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		background: linear-gradient(
			to bottom,
			#ffffff 0%,
			#ffffff 40%,
			#246bce 40%,
			#246bce 100%
		);
	}
	.slider {
		height: fit-content;
	}

	.slider section {
		flex-direction: column;
		align-items: center;
	}
	.control .arrow {
		bottom: 10px;
	}
	.arrow.left {
		left: 10px;
	}

	.arrow.right {
		right: 10px;
	}
}

@media screen and (max-width: 575px) {
	.carousel {
		height: auto;
	}
	.test-1 {
		width: 200px;
		margin-top: 10px;
	}

	.slider {
		height: auto;
	}

	.slider section {
		flex-direction: column;
		align-items: center;
	}

	.quote {
		max-width: 500px;
	}

	/* .arrow.left {
		left: 10px;
	} */
}

@media screen and (max-width: 480px) {
	.carousel {
		height: fit-content;
	}
	.test-1 {
		width: 150px;
		margin-top: 10px;
	}
	.test-image {
		margin-bottom: 30px;
	}
	.quote {
		max-width: 350px;
		text-align: center;
		padding: 10px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		margin-top: 30px;
		margin-bottom: 20px;
	}
	.quote h3 {
		font-size: 18px;
	}

	.quote p {
		font-size: 16px;
	}
	.quote-mark {
		display: block;
		opacity: 0.1;
	}
}

@media screen and (max-width: 360px) {
	.carousel {
		height: fit-content;
	}
	.test-1 {
		width: 150px;
		margin-top: 20px;
	}
	.quote {
		max-width: 150;
		text-align: center;
		padding: 10px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		margin-top: 25px;
		margin-bottom: 40px;
	}
	.quote-mark {
		display: block;
		opacity: 0.1;
	}

	.quote h3 {
		font-size: 16px;
	}

	.quote p {
		font-size: 14px;
	}
}

@import url("https://fonts.googleapis.com/css2?family=Alata&family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.main {
	padding: 50px 0;
	position: relative;
	background-color: #000000;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 50px;
	justify-content: center;
	align-items: center;
	height: fit-content;
}
.required {
	color: #ff6f43;
}
.main img {
	width: 50px;
	position: absolute;
}
.main form {
	margin: auto;
	width: 80%;
	display: flex;
	flex-direction: column;
	gap: 20px;
}
.formtitle {
	width: fit-content;
	text-align: center;
	display: flex;
	flex-direction: column;
	gap: 5px;
}
.formtitle h1 {
	color: #ff6f43;
	font-size: 45px;
	font-weight: 600;
	letter-spacing: -1.5px;
}
.formtitle small {
	color: #fff;
	font-size: 15px;
}
.formtitle p {
	font-weight: 500;
	font-size: 20px;
	margin-top: 30px;
	color: #007bb8;
}

.smallinputholder {
	display: flex;
	gap: 20px;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}

.inputholder {
	display: flex;
	flex-direction: column;
	gap: 5px;
	width: 100%;
}
.inputholder input {
	border: none;
	outline: none;
	padding: 10px;
	height: 50px;
	border-radius: 6px;
}
.inputholder label {
	font-size: 16px;
	font-weight: 500;
	color: #586166;
}
.inputholder input::placeholder {
	color: #8d9396;
}

.inputholder select {
	border: none;
	outline: none;
	padding: 10px;
	height: 50px;
	border-radius: 6px;
	width: 100%;
	display: flex;
	flex-direction: column;
}
.inputholder option {
	color: #8d9396;
	background: #ff6f43;
}

.labelholder {
	display: flex;
	flex-direction: column;
	gap: 5px;
}
.labelholder > label {
	background: #fff;
	padding: 10px;
	display: flex;
	gap: 20px;
	align-items: center;
	height: 50px;
	color: #8d9396;
	font-size: 14px;
	transition: all 0.2s ease;
	cursor: pointer;
	border-radius: 10px;
}
.labelholder label:hover {
	border: 3px solid #ffe3db;
	font-size: 15px;
}

.labelholder input[type="radio"] {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	width: 18px;
	height: 18px;
	border: 2px solid #ffe3db;
	border-radius: 50%;
	outline: none;
	cursor: pointer;
	margin-left: 8px;
	padding: auto;
}

.labelholder .radioLabel {
	display: inline-flex;
	align-items: center;
	padding: 8px;
	border: transparent;
	border-radius: 8px;
	cursor: pointer;
}

.labelholder input[type="radio"]:checked {
	background-color: #ffffff;
	border: 4px solid #ff6f43;
}

.labelholder .radioLabel span {
	margin-left: 4px;
}

.formbutton {
	background: #ff6f43;
	color: #fff;
	padding: 15px 25px;
	border: none;
	outline: none;
	border-radius: 10px;
	font-size: 16px;
	font-weight: 700;
}

/* Larger Laptops and Desktops (1025px and above) */
@media screen and (min-width: 1025px) {
	.main {
		gap: 50px;
	}

	form {
		width: 60%;
	}

	.formtitle h1 {
		font-size: 45px;
	}
	.decor {
		display: block;
	}
}

/* Tablets and Small Laptops (769px - 1024px) */
@media screen and (min-width: 769px) and (max-width: 1024px) {
	.main {
		gap: 40px;
	}

	form {
		width: 70%;
	}

	.formtitle h1 {
		font-size: 35px;
	}
	.smallinputholder {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		width: 100%;
		justify-content: space-between;
		align-items: center;
	}
}

/* Mobile Devices (up to 768px) */
@media screen and (max-width: 768px) {
	.main {
		padding: 50px 0;
		gap: 30px;
	}
	.decor {
		display: none;
	}

	form {
		width: 80%;
	}

	.formtitle h1 {
		font-size: 30px;
	}
	.smallinputholder {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		width: 100%;
		justify-content: space-between;
		align-items: center;
	}
	.inputholder input,
	.inputholder select,
	.inputholder textarea {
		font-size: 16px;
	}

	.labelholder label {
		height: 40px;
		font-size: 13px;
	}
}

/* Mobile Devices (up to 480px) */
@media screen and (max-width: 480px) {
	.main {
		padding: 20px 10px;
		gap: 15px;
	}
	.decor {
		display: none;
	}

	form {
		width: 95%;
		margin-top: 20px !important;
	}

	.formtitle h1 {
		font-size: 35px;
	}
	.formtitle p {
		font-size: 14px;
	}
	.smallinputholder {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		width: 100%;
		justify-content: space-between;
		align-items: center;
	}

	.inputholder input,
	.inputholder select,
	.inputholder textarea {
		font-size: 12px;
		height: 36px;
	}

	.labelholder label {
		height: 32px;
		font-size: 11px;
	}

	.formbutton {
		font-size: 13px;
		padding: 10px 18px;
	}
}

/* Mobile Devices (up to 360px) */
@media screen and (max-width: 360px) {
	.main {
		padding: 15px 5px;
		gap: 10px;
	}

	form {
		width: 95%;
		margin-top: 20px !important;
	}

	.formtitle h1 {
		font-size: 30px;
	}
	.formtitle p {
		font-size: 14px;
	}
	.smallinputholder {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		width: 100%;
		justify-content: space-between;
		align-items: center;
	}

	.inputholder input,
	.inputholder select,
	.inputholder textarea {
		font-size: 10px;
		height: 32px;
	}

	.labelholder label {
		height: 28px;
		font-size: 10px;
	}

	.formbutton {
		font-size: 12px;
		padding: 8px 15px;
	}
	.decor {
		display: none;
	}
}

.counterUp {
	/* margin-bottom: 12em; */
	width: 100%;
	background-color: transparent;
	padding: 50px 80px;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	gap: 20px;
	margin-bottom: 30px;
}

.countItem {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.countItem p {
	font-size: 20px;
	font-weight: 700;
	font-family: "Alata", sans-serif;
	color: #007bb8;
}

.countItem h1 {
	color: #fff;
	font-size: 70px;
	font-weight: 700;
	font-family: "Plus Jakarta Sans", sans-serif;
}

/* Responsive Styles */
@media screen and (max-width: 1199px) {
	.counterUp {
		padding: 40px 60px;
	}

	.countItem h1 {
		font-size: 50px;
	}
	.countItem p {
		color: #007bb8;
	}
}

@media screen and (max-width: 991px) {
	.counterUp {
		padding: 30px 40px;
	}

	.countItem h1 {
		font-size: 50px;
	}
	.countItem p {
		font-size: 16px;
		color: #007bb8;
	}
}

@media screen and (max-width: 767px) {
	.counterUp {
		padding: 30px 30px;
	}

	.countItem h1 {
		font-size: 45px;
	}
	.countItem p {
		font-size: 12px;
		color: #007bb8;
	}
}

@media screen and (max-width: 575px) {
	.counterUp {
		padding: 15px 80px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		align-items: center;
		justify-content: center;
		width: 100%;
		margin: 40px 0;
	}

	.countItem {
		align-items: center;
		text-align: center;
	}

	.countItem h1 {
		font-size: 45px;
	}
	.countItem p {
		font-size: 14px;
		font-weight: 500;
		font-family: "Alata", sans-serif;
		color: #007bb8;
	}
}

@media screen and (max-width: 480px) {
	.counterUp {
		padding: 15px 30px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		column-gap: 80px;
		row-gap: 10px;
		justify-content: center;
		width: 100%;
	}

	.countItem {
		align-items: flex-start;
		text-align: left;
	}

	.countItem h1 {
		font-size: 45px;
		text-align: left;
	}
	.countItem p {
		text-align: left;
		font-size: 12px;
		font-weight: 500;
		font-family: "Alata", sans-serif;
		color: #007bb8;
	}
}

@media screen and (max-width: 360px) {
	.counterUp {
		padding: 15px 30px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		column-gap: 60px;
		row-gap: 10px;
		justify-content: center;
		width: 100%;
	}

	.countItem {
		align-items: flex-start;
		text-align: right;
	}

	.countItem h1 {
		font-size: 40px;
		text-align: right;
	}
	.countItem p {
		font-size: 12px;
		font-weight: 500;
		font-family: "Alata", sans-serif;
		color: #007bb8;
	}
}

@media screen and (max-width: 320px) {
	.counterUp {
		padding: 15px 30px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		column-gap: 30px;
		row-gap: 10px;
		justify-content: center;
		align-items: center;
		width: 100%;
	}

	.countItem {
		align-items: center;
		text-align: center;
	}

	.countItem h1 {
		font-size: 30px;
	}
	.countItem p {
		width: 50px;
		font-size: 10px;
		font-weight: 500;
		font-family: "Alata", sans-serif;
		color: #007bb8;
	}
}

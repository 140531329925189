@import url("https://fonts.googleapis.com/css2?family=Alata&family=Noto+Sans+JP:wght@100;200;300;400;500;600;700;800;900&family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto+Mono:wght@100;200;300;400;500;600;700&display=swap");

.nav {
	text-align: center;
	background-color: #130909;
}
.nav img {
	width: 150px;
}
.container {
	width: 100%;
	background-color: #000;
	display: flex;
	flex-direction: column;
	min-width: 100%;
	height: 600px;
	position: relative;
}

.ticketContainer {
	/* border: 1px solid white; */
	background: #000;
	color: #fff;
	display: flex;
	flex-direction: column;
	width: 70%;
	height: 400px;
	align-items: center;
	justify-content: center;
	gap: 30px;
	margin: auto;
}

.ticketContainer > h2 {
	font-size: 40px;
	font-weight: 500;
	color: #ff6f43;
	line-height: 28px;
}

.ticketMessage {
	/* border: 1px solid white; */
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 80%;
	text-align: center;
	padding: 20px;
	gap: 20px;
}
.ticketMessage h1 {
	font-weight: 700;
	font-size: 50px;
}

.ticketMessage p span {
	color: #ff6f43;
}
.buttons {
	/* margin-top: 20px; */
	gap: 20px;
	display: flex;
	justify-content: space-around;
	width: 50%;
}

/* Responsive styles for specific screen sizes */

/* For screens with max-width 1440px and above */
@media (max-width: 1440px) {
	.ticketMessage {
		width: 100%;
	}
	.ticketMessage p {
		width: 80%;
	}

	.buttons {
		width: 60%;
	}
	.nav {
		text-align: center;
		background-color: #130909;
	}
	.nav img {
		width: 150px;
	}
}

/* For screens with max-width 1200px and above */
@media (max-width: 1200px) {
	.container {
		height: 100vh;
		padding: 0 15px;
		display: flex;
	}
	.ticketContainer {
		width: 100%;
		padding: 0 25px;
	}
	.ticketMessage {
		width: 100%;
		margin: auto;
	}
	.ticketMessage p {
		width: 70%;
	}
	.buttons {
		width: 70%;
	}
	.nav {
		text-align: center;
		background-color: #130909;
	}
	.nav img {
		width: 140px;
	}
}

/* For screens with max-width 1028px and above */
@media (max-width: 1028px) {
	.container {
		height: 700px;
		padding: 0;
		display: flex;
		width: 100%;
	}
	.ticketMessage {
		width: 100%;
		margin: auto;
	}
	.ticketMessage p {
		width: 80%;
	}
	.buttons {
		width: 80%;
		margin-bottom: 60px;
	}
	.nav {
		text-align: center;
		background-color: #130909;
	}
	.nav img {
		width: 130px;
	}
}

/* For screens with max-width 996px and above */
@media (max-width: 996px) {
	.container {
		height: 700px;
		display: flex;
	}
	.ticketMessage {
		width: 100%;
		margin: auto;
	}
	.ticketMessage p {
		width: 80%;
	}
	.buttons {
		width: 70%;
	}
	.nav {
		text-align: center;
		background-color: #130909;
	}
	.nav img {
		width: 130px;
	}
}

/* For screens with max-width 767px and above */
@media (max-width: 767px) {
	.container {
		height: fit-content;
		padding: 0 5px;
		display: flex;
	}
	.ticketContainer {
		max-width: 100%;
		padding: 0 25px;
	}
	.ticketContainer h2 {
		font-size: 30px;
	}
	.ticketMessage {
		width: 100%;
		margin: auto;
	}
	.ticketMessage h1 {
		font-size: 40px;
	}
	.buttons {
		width: 80%;
	}
	.nav {
		text-align: center;
		background-color: #130909;
	}
	.nav img {
		width: 120px;
	}
}

/* For screens with max-width 575px and above */
@media (max-width: 575px) {
	.container {
		height: 700px;
		padding: 0 0px;
		display: flex;
		justify-content: center;
	}
	.ticketContainer {
		height: fit-content;
		margin: auto;
		display: flex;
		flex-direction: column;
		gap: 10px;
		width: 100%;
	}
	.ticketContainer h2 {
		text-align: center;
		font-size: 30px;
	}
	.ticketMessage {
		text-align: center;
		width: 90%;
	}
	.ticketMessage h1 {
		font-size: 35px;
	}
	.ticketMessage p {
		width: 100%;
		font-size: 14px;
		font-weight: 300;
	}
	.buttons {
		margin: auto;
		gap: 20px;
		display: flex;
		gap: 5px;
		justify-content: space-around;
		width: 90%;
	}
	.nav {
		text-align: center;
		background-color: #130909;
	}
	.nav img {
		width: 120px;
	}
}

/* For screens with max-width 480px and above */
@media (max-width: 480px) {
	.container {
		width: 100%;
		height: 700px;
		padding: 0;
		display: flex;
		justify-content: center;
	}
	.ticketContainer {
		height: fit-content;
		margin: auto;
		display: flex;
		flex-direction: column;
		gap: 10px;
		width: 100%;
	}
	.ticketContainer h2 {
		text-align: center;
		font-size: 25px;
	}
	.ticketMessage {
		text-align: center;
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
	.ticketMessage h1 {
		font-size: 24px;
	}
	.ticketMessage p {
		width: 100%;
		font-size: 14px;
		font-weight: 300;
	}
	.buttons {
		margin: auto;
		gap: 20px;
		display: flex;
		flex-direction: column;
		gap: 5px;
		justify-content: space-around;
		width: 90%;
	}
	.nav {
		text-align: center;
		background-color: #130909;
	}
	.nav img {
		width: 120px;
	}
}

/* For screens with max-width 360px and above */
@media (max-width: 360px) {
	.container {
		height: 700px;
		padding: 0 0px;
		display: flex;
		justify-content: center;
	}
	.ticketContainer {
		height: fit-content;
		margin: auto;
		display: flex;
		flex-direction: column;
		gap: 10px;
		width: 100%;
	}
	.ticketContainer h2 {
		text-align: center;
		font-size: 20px;
	}
	.ticketMessage {
		text-align: center;
		width: 100%;
	}
	.ticketMessage h1 {
		font-size: 20px;
	}
	.ticketMessage p {
		width: 100%;
		font-size: 12px;
		font-weight: 300;
	}
	.buttons {
		margin: auto;
		gap: 20px;
		display: flex;
		flex-direction: column;
		gap: 5px;
		justify-content: space-around;
		width: 90%;
		margin-bottom: 40px;
	}
	.nav {
		text-align: center;
		background-color: #130909;
	}
	.nav img {
		width: 120px;
	}
}

/* For screens with max-width 320px and above */
@media (max-width: 320px) {
	.container {
		padding: 0 5px;
	}
	.ticketMessage {
		width: 95%;
	}
	.ticketContainer {
		margin-top: 10px;
	}
	.nav {
		text-align: center;
		background-color: #130909;
	}
	.nav img {
		width: 80px;
	}
}

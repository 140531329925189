@tailwind base;
@tailwind components;
@tailwind utilities;


/* Container styles */
.bg-banner {
    position: relative;
}

.content-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2; 
}

/* Gradient overlay styles */
.gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(36, 33, 33, 0.7), rgba(36, 33, 33, 0.7), rgba(201, 77, 6, 0.7));
    z-index: 1;
}

@media(max-width:700px){
  .gradient-overlay {
    background: linear-gradient(180deg, #E7FDDE, #E7FCDF, #F9EFD6);
}
@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-500%);
  }
}
}

.slide-container {
  display: flex;
  overflow: hidden;
}

.slide-wrapper {
  display: flex;
  animation: slide 30s infinite; 
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-150%);
  }
}

.list-circle li::before {
  position: absolute;
  left: -7px;
  margin-top: 4px;
  content: '';
  display: inline-block;
  width: 1em; 
  height: 1em;
  border: 2px solid #FF6E4A;
  background-color: #007BB8;
  border-radius: 50%;
  margin-right: 0.5em;
}
.list-circle li:first-child::before {
  display: none;
}


@import url("https://fonts.googleapis.com/css2?family=Alata&family=Noto+Sans+JP:wght@100;200;300;400;500;600;700;800;900&family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto+Mono:wght@100;200;300;400;500;600;700&display=swap");

.container {
	height: fit-content;
	background-color: #000;
	padding: 0 80px;
	margin-bottom: 100px;
	display: flex;
	flex-direction: column;
	padding-bottom: 50px;
}

.ticketContainer {
	margin: 60px 0;
	background: #000;
	color: #fff;
	display: flex;
	flex-direction: column;
	gap: 20px;
	max-width: 100%;
}

.ticketContainer > h2 {
	font-size: 40px;
	font-weight: 500;
	color: #ff6f43;
	line-height: 28px;
}
.ticketContainer h1 {
	font-weight: 700;
	font-size: 50px;
}
.ticketMessage {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.ticketMessage p span {
	color: #ff6f43;
}

.mainticket {
	width: 80%;
	height: 100%;
	max-height: 40vh;
	border: 1px solid #fff;
	display: flex;
}

.ticketTitle {
	pointer-events: none;
	background: #ff6f43;
	width: 20%;
	height: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	writing-mode: vertical-lr;
	text-orientation: sideways;
}

.ticketTitle p {
	font-family: "Roboto Mono", sans-serif !important;
	text-align: center;
	transform: rotate(180deg);
}
.ticketBody {
	background: #fff;
	color: #000;
	width: 100%;
	border-right: 5px dashed #ff6f43;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 5px;
}
.part {
	text-align: center;
	width: 100%;
	display: flex;
	gap: 10px;
	background-color: #fff;
}
.part > h2 {
	margin: auto;
	text-align: center;
	font-family: "Roboto Mono", sans-serif;
	font-weight: 700;
	font-size: 30px;
}
.partHolder p {
	font-family: "Roboto Mono", sans-serif;
	font-size: 14px;
	font-weight: 300;
}
.partInner {
	width: 100%;
	background: #000;
	color: #ffe2db;
	padding: 10px;
	border-radius: 6px;
}
.partInner p {
	font-family: "Roboto Mono", sans-serif;
}
.ticketStub {
	pointer-events: none;
	color: #000;
	background: #fff;
	padding: 20px 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 20px;
	align-items: center;
}

.buttons {
	margin-top: 20px;
	gap: 20px;
	display: flex;
	justify-content: space-around;
	width: 50%;
}

/* Responsive styles for specific screen sizes */

/* For screens with max-width 1440px and above */
@media screen and (min-width: 1440px) {
	.container {
		width: 100%;
		height: fit-content;
		padding: 0 35px;
		display: flex;
	}
	.ticketContainer {
		margin-top: 80px;
		width: 100%;
		max-width: 100%;
		padding: 0 25px;
	}
	.ticketMessage {
		width: 100%;
		margin: auto;
	}
	.ticketMessage p {
		width: 60%;
	}
	.mainticket {
		width: 70%;
		max-height: 60vh;
	}
	.ticketTitle {
		width: 30%;
		padding: 30px;
	}

	.ticketBody {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 15px;
		padding: 20px 0;
		width: 100%;
	}
	.part {
		width: 100%;
		display: flex;
		justify-content: center;
		padding: 0 20px;
		/* text-align: center; */
	}
	.partInner {
		max-width: 100%;
		padding: 5px;
	}
	.partHolder {
		width: 100%;
	}
	.ticketStub {
		padding: 45px 75px;
	}
	.buttons {
		width: 60%;
	}
}
@media screen and (max-width: 1440px) {
	.container {
		width: 100%;
		height: fit-content;
		padding: 0 35px;
		display: flex;
	}
	.ticketContainer {
		margin-top: 80px;
		width: 100%;
		max-width: 100%;
		padding: 0 25px;
	}
	.ticketMessage {
		width: 100%;
		margin: auto;
	}
	.ticketMessage p {
		width: 60%;
	}
	.mainticket {
		width: 70%;
		max-height: 60vh;
	}
	.ticketTitle {
		padding: 30px;
		width: 30%;
	}

	.ticketBody {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 15px;
		padding: 20px 0;
		width: 100%;
	}
	.part {
		width: 100%;
		display: flex;
		justify-content: center;
		padding: 0 20px;
		/* text-align: center; */
	}
	.partHolder {
		width: 100%;
	}
	.partInner {
		max-width: 100%;
		padding: 5px;
	}
	.ticketStub {
		padding: 45px 75px;
	}
	.buttons {
		width: 60%;
	}
}

/* For screens with max-width 1200px and above */
@media (max-width: 1280px) {
	.container {
		height: fit-content;
		padding: 0 15px;
		display: flex;
	}
	.ticketContainer {
		max-width: 100%;
		padding: 0 25px;
	}
	.ticketMessage {
		width: 100%;
		margin: auto;
	}
	.ticketMessage p {
		width: 70%;
	}
	.mainticket {
		width: 70%;
		max-height: 60vh;
	}
	.ticketTitle {
		width: 30%;
	}
	.ticketBody {
		padding: 20px;
	}
	.part {
		width: 100%;
		display: flex;
		justify-content: center;
		padding: 0 20px;
		/* text-align: center; */
	}
	.partHolder {
		width: 100%;
	}
	.partInner {
		max-width: 100%;
		padding: 5px;
	}
	.ticketStub {
		padding: 20px 60px;
	}
	.buttons {
		width: 70%;
	}
}

/* For screens with max-width 1028px and above */
@media (max-width: 1024px) {
	.container {
		height: fit-content;
		padding: 0 15px;
		display: flex;
	}
	.ticketContainer {
		max-width: 100%;
		padding: 0 25px;
	}
	.ticketMessage {
		width: 100%;
		margin: auto;
	}
	.ticketMessage p {
		width: 80%;
	}
	.mainticket {
		width: 80%;
		max-height: 60vh;
	}
	.ticketTitle {
		width: 30%;
	}
	.ticketBody {
		padding: 20px;
	}
	.part {
		width: 100%;
		display: flex;
		justify-content: center;
		padding: 0 20px;
		/* text-align: center; */
	}
	.partHolder {
		width: 100%;
	}
	.partInner {
		/* min-width: 400px; */
		max-width: 100%;
		padding: 5px;
	}
	.ticketStub {
		padding: 20px 55px;
	}
	.buttons {
		width: 80%;
		margin-bottom: 60px;
	}
}

/* For screens with max-width 996px and above */
@media (max-width: 996px) {
	.container {
		height: fit-content;
		padding: 0 0px;
		display: flex;
	}
	.ticketContainer {
		max-width: 100%;
		padding: 0 45px;
	}
	.ticketMessage {
		width: 100%;
		margin: auto;
	}
	.ticketMessage p {
		width: 80%;
	}
	.mainticket {
		width: 100%;
		max-height: 60vh;
	}
	.ticketTitle {
		width: 20%;
	}
	.ticketBody {
		padding: 25px 10px;
	}
	.part {
		width: 100%;
	}
	.partHolder {
		width: 100%;
	}
	.partInner {
		padding: 5px;
	}
	.ticketStub {
		padding: 15px 55px;
	}
	.buttons {
		width: 70%;
	}
}

/* For screens with max-width 767px and above */
@media (max-width: 767px) {
	.container {
		height: fit-content;
		padding: 0 5px;
		display: flex;
	}
	.ticketContainer {
		max-width: 100%;
		padding: 0 25px;
	}
	.ticketContainer h2 {
		font-size: 30px;
	}
	.ticketMessage {
		width: 100%;
		margin: auto;
	}
	.ticketMessage h1 {
		font-size: 40px;
	}
	.mainticket {
		width: 100%;
		max-height: 60vh;
	}
	.ticketTitle {
		width: 30%;
	}
	.ticketBody {
		padding: 15px;
	}
	.partInner {
		padding: 5px;
	}
	.ticketStub {
		padding: 15px 40px;
	}
	.buttons {
		width: 80%;
	}
}

/* For screens with max-width 575px and above */
@media (max-width: 575px) {
	.container {
		height: fit-content;
		padding: 0 15px;
		display: flex;
		justify-content: center;
	}
	.ticketContainer {
		height: fit-content;
		margin: 20px auto;
		display: flex;
		flex-direction: column;
		gap: 10px;
		width: 100%;
	}
	.ticketContainer h2 {
		text-align: center;
		font-size: 25px;
	}
	.ticketMessage {
		text-align: center;
		width: 90%;
	}
	.ticketMessage h1 {
		font-size: 30px;
	}
	.ticketMessage p {
		width: 100%;
		font-size: 14px;
		font-weight: 300;
	}
	.mainticket {
		margin: 30px auto;
		width: 70%;
		height: fit-content;
		max-height: fit-content;
		border: 1px solid #fff;
		display: flex;
		flex-wrap: wrap-reverse;
	}
	.ticketTitle {
		background: #ff6f43;
		width: 100%;
		height: fit-content;
		padding: 20px;
		display: flex;
		justify-content: center;
		align-items: center; /* Center the text vertically */
		writing-mode: horizontal-tb; /* Set vertical writing mode */
		text-orientation: upright; /* Set upright text orientation */
	}

	.ticketTitle p {
		font-family: "Roboto Mono", sans-serif !important;
		text-align: center;
		transform: rotate(0deg);
	}

	.ticketBody {
		background: #fff;
		color: #000;
		padding: 20px 10px;
		width: 100%;
		border-right: none;
		border-top: 5px dashed #ff6f43;
		display: flex;
		flex-direction: column;
		gap: 5px;
	}
	.part {
		text-align: center;
		width: 100%;
		display: flex;
		gap: 10px;
		background-color: #fff;
	}
	.part h2 {
		font-family: "Roboto Mono", sans-serif;
		font-weight: 700;
		font-size: 20px;
		text-align: center;
	}
	.partHolder {
		width: 100%;
	}
	.partHolder p {
		font-family: "Roboto Mono", sans-serif;
		font-size: 14px;
		font-weight: 300;
	}
	.partInner {
		width: 100%;
		background: #000;
		color: #ffe2db;
		padding: 10px;
		border-radius: 6px;
	}
	.partInner p {
		font-family: "Roboto Mono", sans-serif;
	}
	.ticketStub {
		color: #000;
		background: #fff;
		padding: 20px 50px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	.buttons {
		margin: auto;
		gap: 20px;
		display: flex;
		flex-direction: column;
		gap: 5px;
		justify-content: space-around;
		width: 90%;
	}
}

/* For screens with max-width 480px and above */
@media (max-width: 480px) {
	.container {
		height: fit-content;
		padding: 0 0px;
		display: flex;
		justify-content: center;
		width: 100%;
	}
	.ticketContainer {
		height: fit-content;
		margin: 20px auto;
		display: flex;
		flex-direction: column;
		gap: 10px;
		width: 100%;
	}
	.ticketContainer h2 {
		text-align: center;
		font-size: 25px;
	}
	.ticketMessage {
		text-align: center;
		width: 90%;
	}
	.ticketMessage h1 {
		font-size: 30px;
	}
	.ticketMessage p {
		width: 100%;
		font-size: 14px;
		font-weight: 300;
	}
	.mainticket {
		margin: 30px auto;
		width: 90%;
		height: fit-content;
		max-height: fit-content;
		border: 1px solid #fff;
		display: flex;
		flex-wrap: wrap-reverse;
	}
	.ticketTitle {
		background: #ff6f43;
		width: 100%;
		height: fit-content;
		padding: 20px;
		display: flex;
		justify-content: center;
		align-items: center; /* Center the text vertically */
		writing-mode: horizontal-tb; /* Set vertical writing mode */
		text-orientation: upright; /* Set upright text orientation */
	}

	.ticketTitle p {
		font-family: "Roboto Mono", sans-serif !important;
		text-align: center;
		transform: rotate(0deg);
	}

	.ticketBody {
		background: #fff;
		color: #000;
		padding: 20px;
		width: 100%;
		border-right: none;
		border-top: 5px dashed #ff6f43;
		display: flex;
		flex-direction: column;
		gap: 5px;
	}
	.part {
		text-align: center;
		width: 100%;
		display: flex;
		gap: 10px;
		background-color: #fff;
	}
	.part h2 {
		font-family: "Roboto Mono", sans-serif;
		font-weight: 700;
		font-size: 20px;
		text-align: center;
	}
	.partHolder {
		width: 100%;
	}
	.partHolder p {
		font-family: "Roboto Mono", sans-serif;
		font-size: 14px;
		font-weight: 300;
	}
	.partInner {
		width: 100%;
		background: #000;
		color: #ffe2db;
		padding: 10px;
		border-radius: 6px;
	}
	.partInner p {
		font-family: "Roboto Mono", sans-serif;
	}
	.ticketStub {
		color: #000;
		background: #fff;
		padding: 20px 50px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	.buttons {
		margin: auto;
		gap: 20px;
		display: flex;
		flex-direction: column;
		gap: 5px;
		justify-content: space-around;
		width: 100%;
	}
}

/* For screens with max-width 360px and above */
@media (max-width: 360px) {
	.container {
		height: fit-content;
		padding: 0 5px;
		display: flex;
		justify-content: center;
	}
	.ticketContainer {
		height: fit-content;
		margin: 20px auto;
		display: flex;
		flex-direction: column;
		gap: 10px;
		width: 100%;
	}
	.ticketContainer h2 {
		text-align: center;
		font-size: 25px;
	}
	.ticketMessage {
		text-align: center;
		width: 90%;
	}
	.ticketMessage h1 {
		font-size: 25px;
	}
	.ticketMessage p {
		width: 100%;
		font-size: 12px;
		font-weight: 300;
	}
	.mainticket {
		margin: 30px auto;
		width: 90%;
		height: fit-content;
		max-height: fit-content;
		border: 1px solid #fff;
		display: flex;
		flex-wrap: wrap-reverse;
	}
	.ticketTitle {
		background: #ff6f43;
		width: 100%;
		height: fit-content;
		padding: 20px;
		display: flex;
		justify-content: center;
		align-items: center; /* Center the text vertically */
		writing-mode: horizontal-tb; /* Set vertical writing mode */
		text-orientation: upright; /* Set upright text orientation */
	}

	.ticketTitle p {
		font-family: "Roboto Mono", sans-serif !important;
		text-align: center;
		transform: rotate(0deg);
	}

	.ticketBody {
		background: #fff;
		color: #000;
		padding: 20px;
		width: 100%;
		border-right: none;
		border-top: 5px dashed #ff6f43;
		display: flex;
		flex-direction: column;
		gap: 5px;
	}
	.part {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 10px;
		background-color: #fff;
	}
	.part h2 {
		font-family: "Roboto Mono", sans-serif;
		font-weight: 700;
		font-size: 20px;
		text-align: center;
	}
	.partHolder {
		display: flex;
		flex-direction: column;
		gap: 5px;
	}
	.partHolder p {
		font-family: "Roboto Mono", sans-serif;
		font-size: 14px;
		font-weight: 300;
	}
	.partInner {
		width: 100%;
		background: #000;
		color: #ffe2db;
		padding: 10px;
		border-radius: 6px;
	}
	.partInner p {
		font-family: "Roboto Mono", sans-serif;
	}
	.ticketStub {
		color: #000;
		background: #fff;
		padding: 20px 50px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	.buttons {
		margin: auto;
		gap: 20px;
		display: flex;
		flex-direction: column;
		gap: 5px;
		justify-content: space-around;
		width: 100%;
		margin-bottom: 40px;
	}
}

/* For screens with max-width 320px and above */
@media (max-width: 300px) {
	.container {
		padding: 0 5px;
	}
	.ticketMessage {
		width: 95%;
	}
	.ticketContainer {
		margin-top: 10px;
	}
}
